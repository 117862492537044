import { useEffect, useState } from 'react';
import { Box, Divider, Grid, IconButton, Stack, Typography, styled } from '@mui/material';
import TelegramLoginButton from 'react-telegram-login';
import { useSnackbar } from 'notistack';
import { LoginSocialFacebook } from 'reactjs-social-login';
import useLocales from '../../../../hooks/useLocales';

import { useDispatch, useSelector } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';

import Login from './Login';
import Register from './Register';
import CustomModal from '../../../../components/CustomModal';
import Image from '../../../../components/Image';
import conObj from '../../../../utils/connection-assistant';
import useAuth from '../../../../hooks/useAuth';

import palette from '../../../../theme/palette';
import { telegramId } from '../../../../config';
import useResponsive from '../../../../hooks/useResponsive';
import Iconify from '../../../../components/Iconify';

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'sans-serif',
  fontWeight: 500,
  color: palette.visitor.dialog.footerText,
  cursor: 'pointer',
}));

const SocialIconCon = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 'fit-content !important',
  display: 'flex !important',
  alignItems: 'center !important',
  justifyContent: 'center !important',
  cursor: 'pointer !important',
}));

const ContainerHeader = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  bottom: '1.4rem',
  display: 'flex',
  // padding: '1rem',
}));

const ContainerHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '1rem',
}));

const CustomHr = ({ style }) => (
  <hr style={{ borderTop: `0.03rem solid ${palette.visitor.dialog.hr}`, borderBottom: 'none', height: 1, ...style }} />
);

const SocialIcon = styled(Image)(({ theme }) => ({
  width: '35px',
  height: '35px',
  borderRadius: '5px',
  cursor: 'pointer',
}));

const LoginSignupDialog = ({ setOpenForgotPasswordDialog }) => {
  const { login } = useAuth();

  const dispatch = useDispatch();

  const { isOpenLoginSignupDialog } = useSelector((x) => x.authPages);

  const [activeTab, setActiveTab] = useState('1');

  const [authData, setAuthData] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const { translate, currentLang } = useLocales();

  const isSm = useResponsive('down', 'lg');

  useEffect(() => {
    if (isOpenLoginSignupDialog?.isLogin) setActiveTab('1');
    if (!isOpenLoginSignupDialog?.isLogin) setActiveTab('2');
  }, [isOpenLoginSignupDialog]);

  const handleTelegramLogin = () => {
    window?.Telegram?.Login.auth({ bot_id: telegramId, request_access: true }, (data) => {
      if (!data) {
        // authorization failed
      }

      // Here you would want to validate data like described there https://core.telegram.org/widgets/login#checking-authorization
      thirdPartyAuth(data);
    });
  };

  const thirdPartyAuth = async (response, type = 'telegram') => {
    try {
      const apiResponse = await conObj.post('users/tpp_login.json', {
        'user_third_party_login-data': JSON.stringify(response),
        'user_third_party_login-platform': type,
      });

      let isSuccess = false;

      const responseData = apiResponse?.data?.data;

      console.log(response, responseData?.registerNow);

      if (responseData?.registerNow) {
        setAuthData({ ...response, platform: type });
        setActiveTab('2');
        isSuccess = true;
      }

      if (!responseData?.registerNow && responseData?.token) {
        await login(
          {},
          {
            token: responseData?.token,
            firstName: responseData?.first_name,
            username: responseData?.username,
            fullName: responseData?.full_name,
            lastName: responseData?.last_name,
            email: responseData?.email,
          }
        );
        isSuccess = true;
      }

      if (!isSuccess) {
        throw new Error(`Login to ${type} failed!`);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  return (
    <CustomModal
      fullScreen={isSm}
      hasClose={false}
      maxWidth={'xs'}
      PaperProps={{ sx: { backgroundColor: 'rgb(37, 38, 49)' } }}
      open={isOpenLoginSignupDialog.open}
      onClose={() => dispatch(openLoginSignup({ open: false, isLogin: true }))}
    >
      <Box sx={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
        <Box sx={{ width: '100%', height: '200px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Image
              // src={
              //   currentLang?.value === 'bn'
              //     ? require('../../../../assets/visitor/visitor_banner_bn.jpg')
              //     : require('../../../../assets/visitor/visitor_banner_en.jpg')
              // }
              src={require('../../../../assets/brand-logo.png')}
              sx={{ width: '250px' }}
            />
          </Box>
        </Box>

        <ContainerHeader
          sx={{
            justifyContent: 'center',
          }}
        >
          <ContainerHeaderWrapper
            sx={{
              alignItems: 'end',
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.4rem' },
                fontWeight: 500,
                textShadow: '1px 2px 5px black',
              }}
            >
              {translate(
                isOpenLoginSignupDialog.isLogin
                  ? translate('welcome_back_good_luck')
                  : translate('welcome_to_best_casino')
              )}
            </Typography>
            {/* <Box sx={{ width: '10rem', mr: '-1rem', overflow: 'hidden' }}>
              <Image src={require('../../../../assets/brand-logo.png')} sx={{ width: '100%' }} />
            </Box> */}
          </ContainerHeaderWrapper>
        </ContainerHeader>
      </Box>
      <Box
        sx={{
          overflow: 'hidden',
          color: 'white',
          // mt: '-3rem',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            borderTopRightRadius: '1rem',
            borderTopLeftRadius: '1rem',
            width: '100%',
            height: '2rem',
            overflow: 'hidden',
            backgroundColor: 'rgba(37, 38, 49, 0.8)',
            WebkitBackdropFilter: 'blur(4px)',
            backdropFilter: 'blur(4px)',
          }}
        />
        {/* </Box> */}
        <Box sx={{ px: 3, pb: 3 }}>
          {activeTab === '1' ? (
            <Login
              setOpenForgotPasswordDialog={setOpenForgotPasswordDialog}
              setActiveTab={setActiveTab}
              setAuthData={setAuthData}
            />
          ) : (
            <Register setActiveTab={setActiveTab} authData={authData} />
          )}
          {/* <Box>
            <Stack direction="row" alignItems={'center'} sx={{ mt: 4 }}>
              {!authData || activeTab === '1' ? (
                <>
                  <CustomHr style={{ flexGrow: 1 }} />
                  <Typography sx={{ px: 1, fontSize: '0.8rem', fontWeight: 500 }}>
                    {translate('login_directly')}
                  </Typography>
                  <CustomHr style={{ flexGrow: 1 }} />
                </>
              ) : (
                ''
              )}
            </Stack>
          </Box>
          {!authData || activeTab === '1' ? (
            <Box>
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                spacing={1}
                sx={{ px: 1, pt: 0.5 }}
              >
                <IconButton size="medium">
                  <Iconify icon="ri:google-fill" sx={{ fontSize: '1.5rem' }} />
                </IconButton>
                <IconButton size="medium">
                  <Iconify icon="mingcute:telegram-fill" sx={{ fontSize: '1.5rem' }} />
                </IconButton>
              </Stack>
            </Box>
          ) : (
            ''
          )} */}
        </Box>
      </Box>
    </CustomModal>
  );
};

export default LoginSignupDialog;

import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem } from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import CustomModal from '../../../components/CustomModal';
import ChangePassword from '../../../pages/auth/ChangePassword';
import { useDispatch, useSelector } from '../../../redux/store';
import { closeChangePwd, openChangePwd } from '../../../redux/slices/authPages';
import useLocales from '../../../hooks/useLocales';
import { fNumberDecimal } from '../../../utils/formatNumber';
import Iconify from '../../../components/Iconify';
import { openConfirm } from '../../../redux/slices/confirm';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { logout } = useAuth();

  const { translate } = useLocales();

  const { isOpenChangePwd } = useSelector((x) => x.authPages);

  const { memberBalance, promotions, userProfile } = useSelector((x) => x.lookup);

  const dispatch = useDispatch();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const [referralDepositBonusId, setReferralDepositBonusId] = useState(null);

  // useEffect(() => {
  //   const referralDepositBonusType = promotions?.data?.filter((f) => f.promotion_type === 'referral_deposit_bonus');

  //   if (referralDepositBonusType?.length > 0) {
  //     setReferralDepositBonusId(referralDepositBonusType[0]?.id);
  //   }
  // }, [promotions]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/', { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ml: 0.5,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {/* <Image src={require('../../../assets/profile.png')} sx={{ width: 30, height: 30, cursor: 'pointer' }} /> */}
        <Iconify icon={'mdi:user-circle'} sx={{ cursor: 'pointer' }} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {userProfile?.full_name}
          </Typography>
          <Typography variant="body2" sx={{ color: '#cbd3dc' }} noWrap>
            {userProfile?.email}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.primary' }} noWrap>
            {`${memberBalance?.currencyCode} ${
              memberBalance?.displayBalance ? fNumberDecimal(memberBalance?.displayBalance) : 0
            }`}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed', borderColor: 'white' }} />

        <MenuItem
          onClick={() => {
            navigate('/user/account');
            handleClose();
          }}
        >
          {translate('account')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate('/user/banks');
            handleClose();
          }}
        >
          {translate('bank_account')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            // if (referralDepositBonusId)
            navigate(`/referral`);
            handleClose();
          }}
        >
          {translate('referral')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            dispatch(openChangePwd());
            handleClose();
          }}
        >
          {translate('change_password')}
        </MenuItem>

        <MenuItem
          onClick={() =>
            openConfirm({
              title: translate('logout_confirmation'),
              subTitle: translate('are_you_sure_to_logout'),
              onConfirm: handleLogout,
            })
          }
        >
          {translate('logout')}
        </MenuItem>
      </MenuPopover>

      <CustomModal
        maxWidth={'sm'}
        open={isOpenChangePwd}
        onClose={() => dispatch(closeChangePwd())}
        children={<ChangePassword />}
      />
    </>
  );
}

import React, { useEffect, useState } from 'react';
// @mui
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import { useSelector } from '../../../redux/store';
import Iconify from '../../../components/Iconify';
import CustomModal from '../../../components/CustomModal';

// ----------------------------------------------------------------------

export default function LanguagePopover({ sx = {}, CustomBtn, PopoverProps = {}, isSideNav = false }) {
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const { siteSettings } = useSelector((x) => x.lookup);

  const [customLangs, setCustomLangs] = useState([]);

  const [open, setOpen] = useState(null);

  const [selectedLang, setSelectedLang] = useState(currentLang);

  const domainLocale = process.env.REACT_APP_LOCALE;

  const langStorage = localStorage.getItem('i18nextLng');

  const { translate } = useLocales();

  useEffect(() => {
    // setCustomLangs(allLangs.filter((f) => f.value === 'en' || f.value === domainLocale));
    if (siteSettings?.languages?.length > 0) {
      const matchingLangs = allLangs;
      // I JUST COMMENT THIS TEMPORARILY
      // .filter((obj1) =>
      //   siteSettings?.languages.some((obj2) => obj1.value === obj2.locale)
      // );

      if (matchingLangs) {
        setCustomLangs(matchingLangs);

        if (matchingLangs.findIndex((ml) => ml.value === langStorage) === -1) {
          handleChangeLang(matchingLangs.find((f) => f.value === domainLocale)?.value);
          console.log('matching');
        }
      } else {
        console.log('No Matching languages');
      }
    }
  }, [siteSettings?.languages]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang) => {
    // localStorage.setItem('i18nextLng', newLang);
    onChangeLang(newLang);
    handleClose();
    window.location.reload();
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(e) => handleOpen(e)}>
        <Stack sx={{ flex: 1 }} direction={'row'} spacing={2}>
          {CustomBtn ? (
            React.cloneElement(CustomBtn, { title: selectedLang?.label })
          ) : (
            <Image
              disabledEffect
              src={selectedLang.icon}
              alt={selectedLang.label}
              sx={{ width: isSideNav ? 25 : 22, height: isSideNav ? 25 : 22, borderRadius: '50%' }}
            />
          )}
          {isSideNav && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 900,
                fontFamily: 'sans-serif',
                lineHeight: '1.5',
                textDecoration: 'none',
                marginLeft: '16px',
              }}
            >
              {selectedLang?.label}
            </Typography>
          )}
        </Stack>
      </Box>

      {/* <MenuPopover */}
      <CustomModal open={Boolean(open)} anchorEl={open} onClose={handleClose} maxWidth={'xs'} {...PopoverProps}>
        <Stack spacing={0.75}>
          <Box sx={{ display: 'flex', px: 2, pt: 2, pb: 1, borderBottom: '1px solid #fff' }}>
            <Typography sx={{ fontWeight: 900 }}>{translate('select_language')}</Typography>
          </Box>
          {customLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                if (!option?.isFake) {
                  handleChangeLang(option.value);
                  setSelectedLang(option);
                }
                handleClose();
              }}
            >
              <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </CustomModal>
      {/* </MenuPopover> */}
    </>
  );
}
